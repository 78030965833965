// Load Bootstrap JS
import bootstrap from 'bootstrap';
import $ from 'jquery';
import select2 from 'select2';
// Load Styles
import '../scss/main.scss';

function selectFormatState (state) {
    if (!state.id) {
        return state.text;
    }
    var baseUrl = "../../assets/images/currency-icons";
    var $state = $(
        '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
    );
    return $state;
};

// App code
$(document).ready(function() {
    $('#fromSelect').select2({
        selectOnClose: true,
        templateResult: selectFormatState
    });
});

$(document).ready(function() {
    $('#toSelect').select2({
        selectOnClose: true,
        templateResult: selectFormatState
    });
    (function(d){d.fn.shuffle=function(c){c=[];return this.each(function(){c.push(d(this).clone(true))}).each(function(a,b){d(b).replaceWith(c[a=Math.floor(Math.random()*c.length)]);c.splice(a,1)})};d.shuffle=function(a){return d(a).shuffle()}})(jQuery);
});